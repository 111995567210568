import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import type {
  EditorReadyOptions,
  InitialAppData,
} from '@wix/platform-editor-sdk';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { onEditorReady } from './editor-app/onEditorReady';
import { getIsStudio, isMobileViewport } from './api/utils';
import {
  setAppMobileGfpp,
  setAppPresetsData,
} from './editor-app/utils/manifastUtils';

export function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
}

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  context,
  flowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;
  // const { translations } = flowAPI;
  // const t = translations.t as TFunction;
  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);
  const isStudio = getIsStudio(context);

  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  setAppMobileGfpp(manifest);
  const isMobile = await isMobileViewport(editorSDK);
  setAppPresetsData(manifest, isMobile, isStudio);
  return manifest;
};

// export const exports = (editorSDK) => ({
//   editor: {
//     getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
//       const preset = await editorSDK.application.appStudioWidgets.getPreset(
//         '',
//         {
//           componentRef: widgetRef,
//         },
//       );
//       return `externalPreset-${preset.style}`;
//     },
//   },
// });
export const exports = (
  editorSDK: FlowEditorSDK,
  context: EditorReadyOptions,
) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style;
    },
  },
});
