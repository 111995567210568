import { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  onPresetChange,
  onSitePublish,
  onClickWidgetGFPP,
  onClickComponentGFPP,
} from './utils';
import { initDeviceSwitchEventListener } from './onDeviceChanged';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
) => {
  await editorSDK.addEventListener('siteWasPublished', async (event) => {
    await onSitePublish(editorSDK, flowAPI);
  });

  await initDeviceSwitchEventListener({ editorSDK });

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    await onClickWidgetGFPP(editorSDK, flowAPI, options, event);
  });

  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    await onClickComponentGFPP(editorSDK, flowAPI, options, event);
  });

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      await onPresetChange(editorSDK, flowAPI, event);
    },
  );
};
