import { CollapsedExpandedComponentRef } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getElementsByPreset } from '../../panels/showHidePanel/showHideData';
import { bi } from '../../utils/bi';
import { presets } from '../../utils/presets';

export const onSitePublish = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  const allAppRefComponents =
    await editorSDK.document.components.refComponents.getAllAppRefComponents(
      'token',
    );
  const allCollapsedComponentsRefs =
    await editorSDK.document.components.refComponents.getCollapsedRefComponents(
      'token',
      { componentRef: allAppRefComponents[0] },
    );

  const preset = editorSDK.document.application.appStudioWidgets.getPreset(
    'token',
    {
      componentRef: allAppRefComponents[0],
    },
  );
  preset.then(async (res: any) => {
    const elementsData = getElementsByPreset(
      res.layout,
      flowAPI.translations.t,
    );
    const result = elementsData.reduce((acc, obj) => {
      const name = obj.biName;
      const hidden = allCollapsedComponentsRefs.some(
        (ref: CollapsedExpandedComponentRef) =>
          ref.role === obj.identifier.role,
      );
      if (name) {
        return {
          ...acc,
          [name]: !hidden,
        };
      }
      return acc;
    }, {});
    const presetName = presets.find((preset) => preset.id === res.style)?.name;
    bi(flowAPI).shareYourMobilePlaceDetails({
      ...result,
      presetName,
    });
  });
};
